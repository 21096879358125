/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

// Formik and Yup
import { useFormik } from "formik";
import * as Yup from "yup";

// Utils component
import TextError from "utils/TextError";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Dashboard components
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDCard from "components/MDCard";
import MDInput from "components/MDInput";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";

import DatePicker from "react-datepicker";

// React-Select component and styling
import Select from "react-select";
import "../../../index.css";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

import { CSVLink } from "react-csv";

// Functions from store
import {
  getSubModulesByRoleId,
  getConsolidatedPIVReports,
  downloadConsolidatedPIV,
} from "../../../store";

// Cookies
import Cookies from "universal-cookie";

// Config file
import Config from "../../../config/index";
import { addDays, addMinutes, format, subDays } from "date-fns";
import { json } from "react-router-dom";

let desLength = Config.descriptionLength;
const cookies = new Cookies();

const styleForEditExpiry = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  boxShadow: 24,
  p: 0,
  pt: 3,
};

const styleForDownloadModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  color: "black",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const styleForLocationsModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 380, sm: 380, md: 800, lg: 1225, xl: 1225 },
  height: "fit-content",
  maxHeight: { xs: 340, sm: 340, md: 440, lg: 600, xl: 600 },
  overflow: "scroll",
  color: "black",
  boxShadow: 24,
  p: 2,
  pt: 3,
};

const ConsolidatedPIVReport = ({
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
  getConsolidatedPIVReports,
  getConsolidatedPIVReportsReducer,
  downloadConsolidatedPIV,
  pivForDownload,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");
  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;
  let userId = loginDetails?.data.userId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let reportsModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "07390a6c-3bf9-4474-9396-b7d6192eb805"
  );

  let viewReportsAccess = reportsModule
    ? reportsModule.actionId.includes("c361e515-e884-4b60-bf8f-a642083e48bd")
    : null;

  const [pageNo, setPageNo] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [searchObject, setSearchObject] = useState(JSON.stringify({}));
  const [paginationObj, setPaginationObj] = useState({});
  const [loading, setLoading] = useState(true);

  const importPageNo = (pageIndex) => {
    setPageNo(pageIndex === -1 ? pageNo : pageIndex);
  };

  const importRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
  };

  const importSearch = (globalFilter) => {
    setSearchObject(globalFilter ? JSON.stringify(globalFilter) : "");
  };
  const [statusSelected, setStatusSelected] = useState("");
  const [startDate, setStartDate] = useState(new Date());

  let isoStartDate;
  let isoEndDate;
  const minDate = subDays(startDate, 60);
  const maxDate = addDays(startDate, 60);
  const [endDate, setEndDate] = useState(minDate);
  const maxEndDate = addDays(endDate, 60);

  useEffect(() => {
    if (startDate || endDate) {
      isoStartDate = new Date(
        (startDate ? startDate : new Date()).getTime() -
          (startDate ? startDate : new Date()).getTimezoneOffset() * 60000
      ).toISOString();

      const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
      };

      const formattedStartDate = formatDate(new Date(isoStartDate));

      isoEndDate = new Date(
        (endDate ? endDate : new Date()).getTime() -
          (endDate ? endDate : new Date()).getTimezoneOffset() * 60000
      ).toISOString();

      const formattedEndDate = formatDate(new Date(isoEndDate));
    }
    // Now you can use both formattedStartDate and formattedEndDate
    getConsolidatedPIVReports(
      isoStartDate ? isoStartDate.slice(0, 10) : "",
      isoEndDate ? isoEndDate.slice(0, 10) : "",
      statusSelected ? statusSelected.value : "",
      pageNo,
      rowsPerPage,
      searchObject,
      jwtToken
    );
  }, [startDate, endDate, statusSelected, pageNo, rowsPerPage, searchObject]);

  // useEffect(() => {
  //   getConsolidatedPIVReports(
  //     isoStartDate ? isoStartDate : "",
  //     isoEndDate ? isoEndDate : "",
  //     statusSelected ? statusSelected.value : "",
  //     pageNo,
  //     rowsPerPage,
  //     searchObject,
  //     jwtToken
  //   );
  // }, [pageNo, rowsPerPage, searchObject]);

  const chargedTypeOptions = [
    {
      label: "All Status",
      value: "",
    },
    {
      label: "Force Closed",
      value: "Force Closed",
    },
    {
      label: "Charging Not Started",
      value: "Charging Not Started",
    },
    {
      label: "Under Charged",
      value: "Under Charged",
    },
    {
      label: "Over Charged",
      value: "Over Charged",
    },
    {
      label: "Limit Charged",
      value: "Limit Charged",
    },
  ];

  const [state, setState] = useState({
    columns: [
      { Header: "PO No", accessor: "poNumber", align: "left" },

      { Header: "Batch No", accessor: "batchNumber", align: "left" },
      { Header: "Batch Qty", accessor: "batchSize", align: "left" },
      { Header: "UOM", accessor: "batchUom", align: "left" },
      { Header: "Phase No", accessor: "phaseNumber", align: "left" },
      { Header: "Phase Des", accessor: "phaseDescription" },
      { Header: "Pick List ID", accessor: "pickupListCode" },
      { Header: "Bulk Code", accessor: "finalMaterialCode" },
      { Header: "Bulk Description", accessor: "finalMaterialDescription" },
      { Header: "Item Code", accessor: "materialCode" },
      { Header: "Item Description", accessor: "materialDescription" },
      { Header: "Item Qty", accessor: "qty" },
      { Header: "Item UOM", accessor: "uom" },
      { Header: "upper Limit", accessor: "upperLimit", align: "left" },
      { Header: "lower Limit", accessor: "lowerLimit", align: "left" },
      { Header: "Resource Category", accessor: "resourceCategory" },
      { Header: "Resource Name", accessor: "resourceName" },
      { Header: "From Loc", accessor: "source" },
      { Header: "To Loc", accessor: "destination" },
      { Header: "Material Type", accessor: "materialType", align: "left" },

      {
        Header: "PO Release Date",
        accessor: "poReleaseDate",
        searchType: "date",
        Cell: ({ value }) => {
          return value !== "NA"
            ? format(
                addMinutes(
                  new Date(value),
                  new Date(value).getTimezoneOffset()
                ),
                "dd/MM/yyyy, HH:mm"
              )
            : "NA";
        },
      },
      {
        Header: "Material Release Date",
        accessor: "matIssuanceDate",
        searchType: "date",
        Cell: ({ value }) => {
          return value !== "NA"
            ? format(
                addMinutes(
                  new Date(value),
                  new Date(value).getTimezoneOffset()
                ),
                "dd/MM/yyyy, HH:mm"
              )
            : "NA";
        },
      },
      {
        Header: "Store Allocation Date",
        accessor: "storeIssuanceDate",
        searchType: "date",
        Cell: ({ value }) => {
          return value !== "NA"
            ? format(
                addMinutes(
                  new Date(value),
                  new Date(value).getTimezoneOffset()
                ),
                "dd/MM/yyyy, HH:mm"
              )
            : "NA";
        },
      },
      {
        Header: "Picked Date",
        accessor: "releasePickuplistDate",
        searchType: "date",
        Cell: ({ value }) => {
          return value !== "NA"
            ? format(
                addMinutes(
                  new Date(value),
                  new Date(value).getTimezoneOffset()
                ),
                "dd/MM/yyyy, HH:mm"
              )
            : "NA";
        },
      },
      // {
      //   Header: "Kitted Date",
      //   accessor: "storeIssuanceDate",
      //   searchType: "date",
      //   Cell: ({ value }) => {
      //     return value
      //       ? format(
      //           addMinutes(
      //             new Date(value),
      //             new Date(value).getTimezoneOffset()
      //           ),
      //           "dd/MM/yyyy, HH:mm"
      //         )
      //       : "NA";
      //   },
      // },
      // {
      //   Header: "Putaway Date",
      //   accessor: "storeIssuanceDate",
      //   searchType: "date",
      //   Cell: ({ value }) => {
      //     return value
      //       ? format(
      //           addMinutes(
      //             new Date(value),
      //             new Date(value).getTimezoneOffset()
      //           ),
      //           "dd/MM/yyyy, HH:mm"
      //         )
      //       : "NA";
      //   },
      // },
      {
        Header: "Phase Allocation Date",
        accessor: "productionUserAllocationTime",
        searchType: "date",
        Cell: ({ value }) => {
          return value !== "NA"
            ? format(
                addMinutes(
                  new Date(value),
                  new Date(value).getTimezoneOffset()
                ),
                "dd/MM/yyyy, HH:mm"
              )
            : "NA";
        },
      },
      {
        Header: "Pickup Start Time",
        accessor: "pickupStartTime",
        searchType: "date",
        Cell: ({ value }) => {
          return value !== "NA"
            ? format(
                addMinutes(
                  new Date(value),
                  new Date(value).getTimezoneOffset()
                ),
                "dd/MM/yyyy, HH:mm"
              )
            : "NA";
        },
      },
      {
        Header: "Pickup End Time",
        accessor: "pickupEndTime",
        searchType: "date",
        Cell: ({ value }) => {
          return value !== "NA"
            ? format(
                addMinutes(
                  new Date(value),
                  new Date(value).getTimezoneOffset()
                ),
                "dd/MM/yyyy, HH:mm"
              )
            : "NA";
        },
      },
      {
        Header: "charging Start Time",
        accessor: "chargingStartTime",
        searchType: "date",
        Cell: ({ value }) => {
          return value !== "NA"
            ? format(
                addMinutes(
                  new Date(value),
                  new Date(value).getTimezoneOffset()
                ),
                "dd/MM/yyyy, HH:mm"
              )
            : "NA";
        },
      },
      {
        Header: "charging End Time",
        accessor: "chargingEndTime",
        searchType: "date",
        Cell: ({ value }) => {
          return value !== "NA"
            ? format(
                addMinutes(
                  new Date(value),
                  new Date(value).getTimezoneOffset()
                ),
                "dd/MM/yyyy, HH:mm"
              )
            : "NA";
        },
      },
      {
        Header: "charged Type",
        accessor: "chargedType",
        searchType: "select",
        selectOptions: chargedTypeOptions,
        placeholder: "All",
        align: "left",
      },
      {
        Header: "Officer Id",
        accessor: "outOfLimitApprovedByID",
      },
      {
        Header: "out Of Limit Approved By",
        accessor: "outOfLimitApprovedBy",
        align: "left",
      },

      {
        Header: "Phase Closed Date",
        accessor: "phaseCloseDate",
        searchType: "date",
        Cell: ({ value }) => {
          return value !== "NA"
            ? format(
                addMinutes(
                  new Date(value),
                  new Date(value).getTimezoneOffset()
                ),
                "dd/MM/yyyy, HH:mm"
              )
            : "NA";
        },
      },
      {
        Header: "PO Closed Date",
        accessor: "poClosedTime",
        searchType: "date",
        Cell: ({ value }) => {
          return value !== "NA"
            ? format(
                addMinutes(
                  new Date(value),
                  new Date(value).getTimezoneOffset()
                ),
                "dd/MM/yyyy, HH:mm"
              )
            : "NA";
        },
      },
      { Header: "phase Status", accessor: "phaseStatus", align: "left" },
      {
        Header: "Pickuplist Status",
        accessor: "pickuplistStatus",
        align: "left",
      },
      { Header: "User", accessor: "productionAssignedUser", align: "left" },
      { Header: "Operator", accessor: "operator", align: "left" },
      {
        Header: "sku Details",
        accessor: "skuDetails",
        align: "left",
        cannotSearch: true,
      },
    ],
    rows: [],
  });
  const { columns, rows } = state;

  useEffect(() => {
    let tempSkus = [];
    let data = getConsolidatedPIVReportsReducer.piv.data
      ? getConsolidatedPIVReportsReducer.piv.data
      : [];

    setLoading(getConsolidatedPIVReportsReducer.loading);
    setPaginationObj(getConsolidatedPIVReportsReducer?.piv?.pagination);

    data.map((piv) => {
      let phaseDes = piv.phaseDescription;
      let bulkDes = piv?.finalMaterialDescription;

      let des = piv?.materialDescription;
      let len = desLength;
      const object = {
        poPhaseNumber: `${piv.poNumber} / ${piv.phaseNumber}`,
        poNumber: piv.poNumber,
        phaseNumber: piv.phaseNumber,
        documentNo: piv.poNumber,
        batchNumber: `${piv.batchNumber}`,
        batchSize: `${piv.batchSize}`,
        batchUom: `${piv.batchUom}`,
        phaseDescription: phaseDes ? phaseDes.substring(0, len) : "",
        materialCode: `${piv.materialCode}`,
        materialDescription: `${des ? des.substring(0, len) : ""}`,
        qty: `${piv.materialQty}`,
        uom: piv.materialUom,
        finalMaterialCode: `${piv.finalMaterialCode}`,
        finalMaterialDescription: `${bulkDes ? bulkDes.substring(0, len) : ""}`,
        pickupListCode: piv.pickupListCode,
        resource: `${piv.resourceCategory} / ${piv.resourceName}`,
        resourceCategory: piv.resourceCategory,
        chargedType: piv.chargedType,
        outOfLimitApprovedByID: piv.outOfLimitApprovedByID,
        outOfLimitApprovedBy: piv.outOfLimitApprovedBy,
        upperLimit: piv.upperLimit,
        lowerLimit: piv.lowerLimit,
        resourceName: piv.resourceName,
        source: piv.source,
        destination: piv.destination,
        materialType: piv.materialType,
        poReleaseDate: piv.poReleaseDate,
        matIssuanceDate: piv.matIssuanceDate,
        storeIssuanceDate: piv.storeIssuanceDate,
        releasePickuplistDate: piv.releasePickuplistDate,
        storeUserAllocationTime: piv.storeUserAllocationTime,
        storeAssignedUser: piv.storeAssignedUser,
        pickupStartTime: piv?.pickupStartTime,
        pickupEndTime: piv?.pickupEndTime,
        productionUserAllocationTime: piv.productionUserAllocationTime,
        productionAssignedUser: piv.productionAssignedUser,
        totalChargedQty: piv.totalChargedQty,
        chargingStartTime: piv?.chargingStartTime,
        chargingEndTime: piv?.chargingEndTime,
        phaseCloseDate: piv?.phaseCloseDate,
        poClosedTime: piv?.poClosedTime,
        pickuplistStatus: piv.pickuplistStatus,
        operator: piv.operator,
        officer: piv.officer,
        phaseStatus: piv.phaseStatus,

        skuDetails: piv.skuDetails.length > 0 && (
          <Tooltip title="View SKU Details" style={{ marginLeft: "0.75rem" }}>
            <MDButton
              color="info"
              variant="gradient"
              type="button"
              iconOnly
              size="small"
              onClick={() => {
                setLocationsData(piv?.skuDetails);
                handleOpenLocationsModal();
              }}
            >
              <Icon> visibility_icon </Icon>
            </MDButton>
          </Tooltip>
        ),
      };
      tempSkus.push(object);
    });

    viewReportsAccess && setState({ ...state, rows: tempSkus });
  }, [getConsolidatedPIVReportsReducer]);

  const [locationsState, setLocationsState] = useState({
    columns: [
      { Header: "sku", accessor: "sku" },
      { Header: "operator", accessor: "operator" },
      { Header: "palletId", accessor: "palletId" },

      { Header: "pickedQty", accessor: "pickedQty" },
      { Header: "skuStatus", accessor: "skuStatus" },
      { Header: "chargedQty", accessor: "chargedQty" },
      {
        Header: "Pickup Time",
        accessor: "pickupTime",
        searchType: "date",
        Cell: ({ value }) => {
          return value !== "NA"
            ? format(
                addMinutes(
                  new Date(value),
                  new Date(value).getTimezoneOffset()
                ),
                "dd/MM/yyyy, HH:mm"
              )
            : "NA";
        },
      },
      {
        Header: "Charged Time",
        accessor: "chargedTime",
        searchType: "date",
        Cell: ({ value }) => {
          return value !== "NA"
            ? format(
                addMinutes(
                  new Date(value),
                  new Date(value).getTimezoneOffset()
                ),
                "dd/MM/yyyy, HH:mm"
              )
            : "NA";
        },
      },
      {
        Header: "kitting Time",
        accessor: "kittingTime",
        searchType: "date",
        Cell: ({ value }) => {
          return value !== "NA"
            ? format(
                addMinutes(
                  new Date(value),
                  new Date(value).getTimezoneOffset()
                ),
                "dd/MM/yyyy, HH:mm"
              )
            : "NA";
        },
      },
      {
        Header: "putaway Time",
        accessor: "putawayTime",
        searchType: "date",
        Cell: ({ value }) => {
          return value !== "NA"
            ? format(
                addMinutes(
                  new Date(value),
                  new Date(value).getTimezoneOffset()
                ),
                "dd/MM/yyyy, HH:mm"
              )
            : "NA";
        },
      },

      {
        Header: "allocated Qty",
        accessor: "allocatedQty",
      },
      {
        Header: "charging Status",
        accessor: "chargingStatus",
      },
      {
        Header: "pickupList Code",
        accessor: "pickupListCode",
      },
      {
        Header: "release To ShopFloor Time",
        accessor: "releaseToShopFloorTime",
      },
      { Header: "charged Type", accessor: "chargedType" },

      {
        Header: "Officer Id",
        accessor: "outOfLimitApprovedByID",
      },
      {
        Header: "out Of Limit Approved By",
        accessor: "outOfLimitApprovedBy",
      },
      { Header: "upper Limit", accessor: "upperLimit", align: "left" },
      { Header: "lower Limit", accessor: "lowerLimit", align: "left" },
    ],
    rows: [],
  });

  const { columns: locationsColumns, rows: locationsRows } = locationsState;

  const dateFilterOptions = [
    {
      label: "PO Release Date",
      value: "poReleaseDate",
    },
    {
      label: "Material Release Date",
      value: "matIssuanceDate",
    },
    {
      label: "Store Allocation Date",
      value: "storeIssuanceDate",
    },
    {
      label: "Picked Date",
      value: "releasePickuplistDate",
    },
    {
      label: "Pickup Start Time",
      value: "pickupStartTime",
    },
    {
      label: "Pickup End Time",
      value: "pickupEndTime",
    },
    {
      label: "Charging Start Time",
      value: "chargingStartTime",
    },
    {
      label: "Charging End Time",
      value: "chargingEndTime",
    },
    {
      label: "Phase Closed Date",
      value: "phaseCloseDate",
    },
    {
      label: "PO Closed Date",
      value: "poClosedTime",
    },
  ];
  useEffect(() => {
    setStatusSelected({
      label: dateFilterOptions[0]?.label,
      value: dateFilterOptions[0]?.value,
    });
  }, []);

  const handleChangeStatus = (selectedOption) => {
    setStatusSelected(selectedOption);
  };
  const [locationsModal, setOpenLocationsModal] = useState(false);
  const [locationsData, setLocationsData] = useState([]);

  const [singleLineItemData, setSingleLineItemData] = useState({});

  const handleOpenLocationsModal = () => {
    setOpenLocationsModal(true);
  };

  const handleCloseLocationsModal = () => {
    setOpenLocationsModal(false);
  };

  useEffect(() => {
    let tempLocationsObj = [];

    let data = locationsData ? locationsData : [];

    data.map((data) => {
      const locationObj = {
        sku: data.sku,
        operator: data.operator,
        palletId: data.palletId,
        pickedQty: data.pickedQty,
        skuStatus: data.skuStatus,
        chargedQty: data.chargedQty,
        pickupTime: data.pickupTime,
        chargedTime: data.chargedTime,
        kittingTime: data.kittingTime,
        putawayTime: data.putawayTime,
        allocatedQty: data.allocatedQty,
        chargingStatus: data.chargingStatus,
        pickupListCode: data.pickupListCode,
        releaseToShopFloorTime: data.releaseToShopFloorTime,

        chargedType: data.chargedType,
        outOfLimitApprovedByID: data.outOfLimitApprovedByID,
        outOfLimitApprovedBy: data.outOfLimitApprovedBy,
        upperLimit: data.upperLimit,
        lowerLimit: data.lowerLimit,
      };

      tempLocationsObj.push(locationObj);
    });

    setLocationsState({ ...locationsState, rows: tempLocationsObj });
  }, [locationsData]);

  const [loadingData, setLoadingData] = useState(false);
  const [downloadData, setDownloadData] = useState([]);

  useEffect(() => {
    let tempReports = [];
    let data = pivForDownload.piv.data ? pivForDownload.piv.data : [];

    data.map((piv) => {
      let phaseDes = piv.phaseDescription;
      let bulkDes = piv?.finalMaterialDescription;

      let des = piv?.materialDescription;
      let len = desLength;
      const object = {
        poPhaseNumber: `${piv.poNumber} / ${piv.phaseNumber}`,
        poNumber: piv.poNumber,
        phaseNumber: piv.phaseNumber,
        documentNo: piv.poNumber,
        batchNumber: `${piv.batchNumber}`,
        batchSize: `${piv.batchSize}`,
        batchUom: `${piv.batchUom}`,
        phaseDescription: phaseDes ? phaseDes.substring(0, len) : "",
        materialCode: `${piv.materialCode}`,
        materialDescription: `${des ? des.substring(0, len) : ""}`,
        qty: `${piv.materialQty}`,
        uom: piv.materialUom,
        finalMaterialCode: `${piv.finalMaterialCode}`,
        finalMaterialDescription: `${bulkDes ? bulkDes.substring(0, len) : ""}`,
        pickupListCode: piv.pickupListCode,
        resourceCategory: piv.resourceCategory,
        resourceName: piv.resourceName,
        source: piv.source,
        destination: piv.destination,
        materialType: piv.materialType,
        poReleaseDate:
          piv.poReleaseDate !== "NA"
            ? format(
                addMinutes(
                  new Date(piv.poReleaseDate),
                  new Date(piv.poReleaseDate)?.getTimezoneOffset()
                ),
                "dd/MM/yyyy, HH:mm"
              )
            : "NA",
        matIssuanceDate:
          piv.matIssuanceDate !== "NA"
            ? format(
                addMinutes(
                  new Date(piv.matIssuanceDate),
                  new Date(piv.matIssuanceDate)?.getTimezoneOffset()
                ),
                "dd/MM/yyyy, HH:mm"
              )
            : "NA",
        storeIssuanceDate:
          piv.storeIssuanceDate !== "NA"
            ? format(
                addMinutes(
                  new Date(piv.storeIssuanceDate),
                  new Date(piv.storeIssuanceDate)?.getTimezoneOffset()
                ),
                "dd/MM/yyyy, HH:mm"
              )
            : "NA",
        releasePickuplistDate:
          piv.releasePickuplistDate !== "NA"
            ? format(
                addMinutes(
                  new Date(piv.releasePickuplistDate),
                  new Date(piv.releasePickuplistDate)?.getTimezoneOffset()
                ),
                "dd/MM/yyyy, HH:mm"
              )
            : "NA",
        // storeUserAllocationTime: format(
        //   addMinutes(
        //     new Date(piv.storeUserAllocationTime),
        //     new Date(piv.storeUserAllocationTime)?.getTimezoneOffset()
        //   ),
        //   "do MMMM yyyy"
        // ),
        storeAssignedUser: piv.storeAssignedUser,
        pickupStartTime:
          piv.pickupStartTime !== "NA"
            ? format(
                addMinutes(
                  new Date(piv.pickupStartTime),
                  new Date(piv.pickupStartTime)?.getTimezoneOffset()
                ),
                "dd/MM/yyyy, HH:mm"
              )
            : "NA",
        pickupEndTime:
          piv.pickupEndTime !== "NA"
            ? format(
                addMinutes(
                  new Date(piv.pickupEndTime),
                  new Date(piv.pickupEndTime)?.getTimezoneOffset()
                ),
                "dd/MM/yyyy, HH:mm"
              )
            : "NA",
        productionUserAllocationTime:
          piv.productionUserAllocationTime !== "NA"
            ? format(
                addMinutes(
                  new Date(piv.productionUserAllocationTime),
                  new Date(
                    piv.productionUserAllocationTime
                  )?.getTimezoneOffset()
                ),
                "dd/MM/yyyy, HH:mm"
              )
            : "NA",
        productionAssignedUser: piv.productionAssignedUser,
        totalChargedQty: piv.totalChargedQty,
        chargingStartTime:
          piv.chargingStartTime !== "NA"
            ? format(
                addMinutes(
                  new Date(piv.chargingStartTime),
                  new Date(piv.chargingStartTime)?.getTimezoneOffset()
                ),
                "dd/MM/yyyy, HH:mm"
              )
            : "NA",
        chargingEndTime:
          piv.chargingEndTime !== "NA"
            ? format(
                addMinutes(
                  new Date(piv.chargingEndTime),
                  new Date(piv.chargingEndTime)?.getTimezoneOffset()
                ),
                "dd/MM/yyyy, HH:mm"
              )
            : "NA",
        phaseCloseDate:
          piv.phaseCloseDate !== "NA"
            ? format(
                addMinutes(
                  new Date(piv.phaseCloseDate),
                  new Date(piv.phaseCloseDate)?.getTimezoneOffset()
                ),
                "dd/MM/yyyy, HH:mm"
              )
            : "NA",
        poClosedTime:
          piv.poClosedTime !== "NA"
            ? format(
                addMinutes(
                  new Date(piv.poClosedTime),
                  new Date(piv.poClosedTime)?.getTimezoneOffset()
                ),
                "dd/MM/yyyy, HH:mm"
              )
            : "NA",
        pickuplistStatus: piv.pickuplistStatus,
        operator: piv.operator,
        officer: piv.officer,
        phaseStatus: piv.phaseStatus,
        skuDetails: JSON.stringify(piv.skuDetails),
      };
      tempReports.push(object);
    });
    setDownloadData(tempReports);
  }, [pivForDownload]);

  const convertToCSV = (dataArray) => {
    const headers = [
      "PO No",
      "Batch No",
      "Batch Qty",
      "UOM",
      "Phase No",
      "Phase Des",
      "Pick List ID",
      "Sr NO",
      "Item No",
      "Bulk Code",
      "Bulk Description",
      "Item Code",
      "Item Description",
      "Item Qty",
      "Item UOM",
      "Upper Limit",
      "Lower Limit",
      "Resource Category",
      "Resource Name",
      "From Loc",
      "To Loc",
      "Material Type",

      "PO Release Date",
      "Material Release Date",
      "Store Allocation Date",
      "Phase Allocation Date",
      "Pickup Start Time",
      "Pickup End Time",
      "Charging Start Time",
      "Charging End Time",
      "Charged Type",
      "Officer Id",
      "Out Of Limit Approved By",
      "Phase Closed Date",
      "PO Closed Date",
      "Phase Status",
      "Pickuplist Status",
      "Store User",
      "Store User Allocation Time",
      "Production User",
      "Production User Allocation Time",
      "SKU",
      "Pallet Id",
      "SKU Status",
      "Picked Qty",
      "Allocated Qty",
      "Pickup Time",
      "Kitting Time",
      "Putaway Time",
      "Charging Status",
      "Charging Time",
      "Charged Qty",

      "Operator",
      "Release For ShopFloor Time",
    ];

    let csvContent = headers.join(",") + "\r\n";

    dataArray.forEach((item) => {
      let obj = item;
      obj?.skuDetails?.forEach((e) => {
        const line = [
          obj.poNumber,
          obj.batchNumber,
          obj.batchSize,
          obj.batchUom,
          obj.phaseNumber,
          obj.phaseDescription,
          obj.pickupListCode,
          obj.srNo,
          obj.itemNo,
          obj.finalMaterialCode,
          obj.finalMaterialDescription,
          obj.materialCode,
          obj.materialDescription,
          obj.materialQty,
          obj.materialUom,
          obj.upperLimit,
          obj.lowerLimit,
          obj.resourceCategory,
          obj.resourceName,
          obj.source,
          obj.destination,
          obj.materialType,

          obj.poReleaseDate !== "NA"
            ? format(
                addMinutes(
                  new Date(obj.poReleaseDate),
                  new Date(obj.poReleaseDate)?.getTimezoneOffset()
                ),
                "dd-MM-yyyy HH:mm"
              )
            : "NA",

          obj.matIssuanceDate !== "NA"
            ? format(
                addMinutes(
                  new Date(obj.matIssuanceDate),
                  new Date(obj.matIssuanceDate)?.getTimezoneOffset()
                ),
                "dd-MM-yyyy HH:mm"
              )
            : "NA",
          obj.storeIssuanceDate !== "NA"
            ? format(
                addMinutes(
                  new Date(obj.storeIssuanceDate),
                  new Date(obj.storeIssuanceDate)?.getTimezoneOffset()
                ),
                "dd-MM-yyyy HH:mm"
              )
            : "NA",

          obj.productionUserAllocationTime !== "NA"
            ? format(
                addMinutes(
                  new Date(obj.productionUserAllocationTime),
                  new Date(
                    obj.productionUserAllocationTime
                  )?.getTimezoneOffset()
                ),
                "dd-MM-yyyy HH:mm"
              )
            : "NA",

          obj.pickupStartTime !== "NA"
            ? format(
                addMinutes(
                  new Date(obj.pickupStartTime),
                  new Date(obj.pickupStartTime)?.getTimezoneOffset()
                ),
                "dd-MM-yyyy HH:mm"
              )
            : "NA",

          obj.pickupEndTime !== "NA"
            ? format(
                addMinutes(
                  new Date(obj.pickupEndTime),
                  new Date(obj.pickupEndTime)?.getTimezoneOffset()
                ),
                "dd-MM-yyyy HH:mm"
              )
            : "NA",

          obj.chargingStartTime !== "NA"
            ? format(
                addMinutes(
                  new Date(obj.chargingStartTime),
                  new Date(obj.chargingStartTime)?.getTimezoneOffset()
                ),
                "dd-MM-yyyy HH:mm"
              )
            : "NA",

          obj.chargingEndTime !== "NA"
            ? format(
                addMinutes(
                  new Date(obj.chargingEndTime),
                  new Date(obj.chargingEndTime)?.getTimezoneOffset()
                ),
                "dd-MM-yyyy HH:mm"
              )
            : "NA",
          obj.chargedType,
          obj.outOfLimitApprovedByID,
          obj.outOfLimitApprovedBy,

          obj.phaseCloseDate !== "NA"
            ? format(
                addMinutes(
                  new Date(obj.phaseCloseDate),
                  new Date(obj.phaseCloseDate)?.getTimezoneOffset()
                ),
                "dd-MM-yyyy HH:mm"
              )
            : "NA",

          obj.poClosedTime !== "NA"
            ? format(
                addMinutes(
                  new Date(obj.poClosedTime),
                  new Date(obj.poClosedTime)?.getTimezoneOffset()
                ),
                "dd-MM-yyyy HH:mm"
              )
            : "NA",

          obj.phaseStatus,
          obj.pickuplistStatus,
          obj.storeAssignedUser,
          obj.storeUserAllocationTime !== "NA"
            ? format(
                addMinutes(
                  new Date(obj.storeUserAllocationTime),
                  new Date(obj.storeUserAllocationTime)?.getTimezoneOffset()
                ),
                "dd-MM-yyyy HH:mm"
              )
            : "NA",

          obj.productionAssignedUser,

          obj.productionUserAllocationTime !== "NA"
            ? format(
                addMinutes(
                  new Date(obj.productionUserAllocationTime),
                  new Date(
                    obj.productionUserAllocationTime
                  )?.getTimezoneOffset()
                ),
                "dd-MM-yyyy HH:mm"
              )
            : "NA",
          e.sku,
          e.palletId,
          e.skuStatus,
          e.pickedQty,
          e.allocatedQty,
          e.pickupTime,

          e.kittingTime !== "NA"
            ? format(
                addMinutes(
                  new Date(e.kittingTime),
                  new Date(e.kittingTime)?.getTimezoneOffset()
                ),
                "dd-MM-yyyy HH:mm"
              )
            : "NA",
          e.putawayTime !== "NA"
            ? format(
                addMinutes(
                  new Date(e.putawayTime),
                  new Date(e.putawayTime)?.getTimezoneOffset()
                ),
                "dd-MM-yyyy HH:mm"
              )
            : "NA",
          e.chargingStatus,

          e.chargedTime !== "NA"
            ? format(
                addMinutes(
                  new Date(e.chargedTime),
                  new Date(e.chargedTime)?.getTimezoneOffset()
                ),
                "dd-MM-yyyy HH:mm"
              )
            : "NA",
          e.chargedQty,
          e.operator,
          e.releaseToShopFloorTime !== "NA"
            ? format(
                addMinutes(
                  new Date(e.releaseToShopFloorTime),
                  new Date(e.releaseToShopFloorTime)?.getTimezoneOffset()
                ),
                "dd-MM-yyyy HH:mm"
              )
            : "NA",
        ];

        csvContent += line.join(",") + "\r\n";
      });
    });

    return csvContent;
  };

  const downloadCSV = () => {
    const csvData = convertToCSV(pivForDownload?.piv?.data);
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);

    link.setAttribute("href", url);
    link.setAttribute("download", "Consolidated PIV Report.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const [openDownloadModal, setOpenDownloadModal] = useState(false);
  const [disableDownloadButton, setDisableDownloadButton] = useState(false);
  const [modalMsg, setModalMsg] = useState("");

  const handleOpenDownloadModal = (data = {}) => {
    setOpenDownloadModal(true);
  };

  const handleCloseDownloadModal = () => {
    setDisableDownloadButton(false);
    setOpenDownloadModal(false);
  };

  const downloadHandler = async () => {
    setLoadingData(true);

    let res = await downloadConsolidatedPIV(
      new Date(
        (startDate ? startDate : new Date()).getTime() -
          (startDate ? startDate : new Date()).getTimezoneOffset() * 60000
      ).toISOString(),
      (isoEndDate = new Date(
        (endDate ? endDate : new Date()).getTime() -
          (endDate ? endDate : new Date()).getTimezoneOffset() * 60000
      ).toISOString()),
      statusSelected ? statusSelected.value : "",
      pageNo,
      rowsPerPage,
      searchObject,
      jwtToken
    );

    if (res.status) {
      setModalMsg("Ready for download");
      setLoadingData(false);
      handleOpenDownloadModal();
    }
    if (!res.status) {
      setModalMsg("Could not prepare the data for download!");
      setLoadingData(false);
      setDisableDownloadButton(true);
      handleOpenDownloadModal();
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              {viewReportsAccess && (
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  p={3}
                  // mb={-6}
                >
                  <MDBox
                    display="flex"
                    flexDirection="row"
                    width={{ xs: "auto", lg: "400px" }} // Adjust width as needed
                    style={{ marginRight: "5px" }}
                  >
                    <div>
                      <MDTypography variant="h6" style={{ fontWeight: 500 }}>
                        Start Date
                      </MDTypography>
                      <DatePicker
                        className="form-control"
                        dateFormat="dd/MM/yyyy"
                        placeholderText="End Date"
                        // minDate={minDate}
                        maxDate={maxDate}
                        onChange={(date) => {
                          setEndDate(date);

                          setStartDate(addDays(date, 60));
                        }}
                        withPortal
                        selectsEnd
                        selected={endDate}
                        startDate={startDate}
                        endDate={endDate}
                      />
                    </div>
                    <div style={{ marginLeft: "4px" }}>
                      <MDTypography variant="h6" style={{ fontWeight: 500 }}>
                        End Date
                      </MDTypography>
                      <DatePicker
                        className="form-control"
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Start Date"
                        minDate={endDate}
                        maxDate={startDate}
                        onChange={(date) => setStartDate(date)}
                        withPortal
                        selectsStart
                        selected={startDate}
                        startDate={startDate}
                        endDate={endDate}
                      />
                    </div>
                    <MDBox style={{ width: "300px", marginLeft: "10px" }}>
                      <MDTypography variant="h6" style={{ fontWeight: 500 }}>
                        Date Filter
                      </MDTypography>
                      <Select
                        className="select-css-for-filter"
                        value={statusSelected}
                        options={dateFilterOptions}
                        onChange={handleChangeStatus}
                        autoComplete="off"
                        maxMenuHeight={130}
                      />
                    </MDBox>
                  </MDBox>
                  <MDBox color="text">
                    <Tooltip title="Download">
                      <MDButton
                        style={{ marginRight: "1rem", marginLeft: "10px" }}
                        type="button"
                        variant="outlined"
                        color="error"
                        iconOnly
                        onClick={() => {
                          downloadHandler();
                        }}
                      >
                        <Icon>download</Icon>
                      </MDButton>
                    </Tooltip>
                  </MDBox>
                </MDBox>
              )}

              <MDBox mt={viewReportsAccess ? -3 : 0}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  entriesPerPage={{
                    defaultValue: 30,
                    entries: [30, 50, 75, 100],
                  }}
                  showTotalEntries={true}
                  noEndBorder
                  options={{ draggable: false }}
                  serverSidePagination={true}
                  paginationObj={paginationObj}
                  pageNoValue={pageNo}
                  rowsPerPageValue={rowsPerPage}
                  pageNoProp={importPageNo}
                  rowsPerPageProp={importRowsPerPage}
                  searchProp={importSearch}
                />
              </MDBox>

              <Modal
                open={openDownloadModal}
                onClose={handleCloseDownloadModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={styleForDownloadModal}>
                  <MDTypography>{`${
                    disableDownloadButton
                      ? "Could not prepare the data for download!"
                      : "Ready for download!"
                  }`}</MDTypography>
                  <MDBox mt={2}>
                    <MDButton
                      style={{ width: "48%", marginRight: "0.5rem" }}
                      color="info"
                      variant="outlined"
                      onClick={handleCloseDownloadModal}
                    >
                      Cancel
                    </MDButton>
                    {!disableDownloadButton && (
                      <MDButton
                        style={{ width: "48%", marginRight: "0.5rem" }}
                        type="button"
                        variant="outlined"
                        color="error"
                        onClick={downloadCSV}
                      >
                        Download
                      </MDButton>
                    )}
                  </MDBox>
                </MDCard>
              </Modal>
              <Modal
                open={locationsModal}
                onClose={handleCloseLocationsModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={styleForLocationsModal}>
                  <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                    <MDTypography variant="h4" fontWeight="medium" flexGrow={1}>
                      Locations
                    </MDTypography>
                    <Tooltip title="Close">
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        onClick={handleCloseLocationsModal}
                        style={{
                          background: "whitesmoke",
                          color: "black",
                          borderRadius: 5,
                          marginLeft: "-55px",
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  </MDBox>

                  <MDBox pt={1} pb={3} px={3}>
                    <DataTable
                      table={{
                        columns: locationsColumns,
                        rows: locationsRows,
                      }}
                      isSorted={true}
                      entriesPerPage={{
                        defaultValue: 30,
                        entries: [30, 50, 75, 100],
                      }}
                      showTotalEntries={true}
                      noEndBorder
                      options={{ draggable: false }}
                    />
                  </MDBox>
                </MDCard>
              </Modal>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      {loading && (
        <Backdrop
          sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {loadingData && (
        <Backdrop
          sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <MDTypography color="warning" variant="h2">
            Preparing Data. Please wait...
          </MDTypography>
        </Backdrop>
      )}
      <Footer />
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    getConsolidatedPIVReportsReducer: state.getConsolidatedPIVReportsReducer,
    pivForDownload: state.downloadConsolidatedPIVReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),

    getConsolidatedPIVReports: (
      startDate,
      endDate,
      statusSelected,
      pageNo,
      rowsPerPage,
      search,
      jwtToken
    ) =>
      dispatch(
        getConsolidatedPIVReports(
          startDate,
          endDate,
          statusSelected,
          pageNo,
          rowsPerPage,
          search,
          jwtToken
        )
      ),
    downloadConsolidatedPIV: (
      startDate,
      endDate,
      statusSelected,
      pageNo,
      rowsPerPage,
      search,
      jwtToken
    ) =>
      dispatch(
        downloadConsolidatedPIV(
          startDate,
          endDate,
          statusSelected,
          pageNo,
          rowsPerPage,
          search,
          jwtToken
        )
      ),
  };
};

export default connect(
  mapStateToProps,
  mapStateToDispatch
)(ConsolidatedPIVReport);
