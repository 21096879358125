import {
  GET_CONSOLIDATED_PIV_FOR_REPORTS_REQUEST,
  GET_CONSOLIDATED_PIV_FOR_REPORTS_SUCCESS,
  GET_CONSOLIDATED_PIV_FOR_REPORTS_FAILURE,
  DOWNLOAD_CONSOLIDATED_PIV_REQUEST,
  DOWNLOAD_CONSOLIDATED_PIV_SUCCESS,
  DOWNLOAD_CONSOLIDATED_PIV_FAILURE,
} from "./consolidatedPIVTypes";
import axios from "axios";
import Config from "../../../config/index";

let baseUrl = Config.baseUrl;

const getConsolidatedPIVReportsRequest = () => {
  return {
    type: GET_CONSOLIDATED_PIV_FOR_REPORTS_REQUEST,
  };
};

const getConsolidatedPIVReportsSuccess = (payload) => {
  return {
    type: GET_CONSOLIDATED_PIV_FOR_REPORTS_SUCCESS,
    payload: payload,
  };
};

const getConsolidatedPIVReportsFailure = (error) => {
  return {
    type: GET_CONSOLIDATED_PIV_FOR_REPORTS_FAILURE,
    payload: error,
  };
};

const getConsolidatedPIVReports = (
  startDate,
  endDate,
  dateFilter,
  pageNo,
  rowsPerPage,
  search,
  jwtToken
) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getConsolidatedPIVReportsRequest());
    let url = `${baseUrl}/reports/consolidated_pickuplist_report?startDate=${startDate}&endDate=${endDate}&dateFilter=${dateFilter}&page_number=${
      pageNo + 1
    }&rows_per_page=${rowsPerPage}&search=${search ? search : ""}&download=0`;

    return axios
      .get(url, headers)
      .then((response) => {
        dispatch(getConsolidatedPIVReportsSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(getConsolidatedPIVReportsFailure(err.message));
        return { status: false, data: err.response };
      });
  };
};

const downloadConsolidatedPIVRequest = () => {
  return {
    type: DOWNLOAD_CONSOLIDATED_PIV_REQUEST,
  };
};

const downloadConsolidatedPIVSuccess = (payload) => {
  return {
    type: DOWNLOAD_CONSOLIDATED_PIV_SUCCESS,
    payload: payload,
  };
};

const downloadConsolidatedPIVFailure = (error) => {
  return {
    type: DOWNLOAD_CONSOLIDATED_PIV_FAILURE,
    payload: error,
  };
};

const downloadConsolidatedPIV = (
  startDate,
  endDate,
  dateFilter,
  pageNo,
  rowsPerPage,
  search,
  jwtToken
) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(downloadConsolidatedPIVRequest());
    let url = `${baseUrl}/reports/consolidated_pickuplist_report?startDate=${startDate}&endDate=${endDate}&dateFilter=${dateFilter}&page_number=${
      pageNo + 1
    }&rows_per_page=${rowsPerPage}&search=${search ? search : ""}&download=1`;

    return axios
      .get(url, headers)
      .then((response) => {
        dispatch(downloadConsolidatedPIVSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(downloadConsolidatedPIVFailure(err.response));
        return { status: false, data: err.response };
      });
  };
};

export { getConsolidatedPIVReports, downloadConsolidatedPIV };
