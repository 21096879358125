/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import {
  getMaterialDesc,
  stockCutOver,
  getSubModulesByRoleId,
  getWarehouses,
} from "../../../store";

import Select from "react-select";
import "../../../index.css";

import { useFormik } from "formik";
import * as Yup from "yup";
import TextError from "../../../utils/TextError";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

// @mui material components
import Card from "@mui/material/Card";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

import Cookies from "universal-cookie";

const cookies = new Cookies();

function StockCutOver({
  getMaterialDesc,
  getMaterialDescReducer,
  stockCutOver,
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
  getWarehouses,
  warehouses,
}) {
  let data = cookies.get("loginDetailsForWMS");
  let printerId = cookies.get("printerIdForWarehouse");

  let userId = data?.data?.userId;
  let userName = data?.data?.userName;
  let roleId = data.data.roleId;
  let jwtToken = data?.jwt;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let stockCutOverModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "b92447b0-645d-4a7a-846f-47cf351d33fd"
  );

  let printStockCutOver = stockCutOverModule
    ? stockCutOverModule.actionId.includes(
        "27604158-f00b-4e7c-8b79-c8b5716511fd"
      )
    : null;

  const [isSuccessStockCutOver, setIsSuccessStockCutOver] = useState(false);
  const [isErrorStockCutOver, setIsErrorStockCutOver] = useState(false);

  useEffect(() => {
    if (isErrorStockCutOver) {
      setTimeout(() => {
        setIsErrorStockCutOver(false);
      }, 3000);
    }
  }, [isErrorStockCutOver]);

  useEffect(() => {
    if (isSuccessStockCutOver) {
      setTimeout(() => {
        setIsSuccessStockCutOver(false);
      }, 4000);
    }
  }, [isSuccessStockCutOver]);

  useEffect(() => {
    getWarehouses("", jwtToken);
  }, []);

  const [warehouseOptions, setWarehouseOptions] = useState([]);

  useEffect(() => {
    let tempWarehouseOptions = [];
    let data = warehouses.warehouses ? warehouses.warehouses : [];

    data.map((warehouse) => {
      tempWarehouseOptions.push({
        label: `${warehouse.warehouseCode} / ${warehouse.warehouseName}`,
        value: warehouse.warehouseCode,
      });
    });
    setWarehouseOptions(tempWarehouseOptions);
  }, [warehouses]);

  const [locationSelected, setLocationSelected] = useState("");

  const handleLocationChange = (selectedZone) => {
    setLocationSelected(selectedZone);
  };

  const [materialDescription, setMaterialDescription] = useState("");
  const [uom, setUom] = useState("");

  const initialValues = {
    sapCode: "",
    materialDescription: materialDescription,
    grnNumber: "",
    uom: uom,
    batchNumber: "",

    ShelfLife: new Date().toISOString().slice(0, 10),
    mfgDate: new Date().toISOString().slice(0, 10),
    storingLocation: "",
    totalQty: "",
    noOfPrints: "",
    palletId: "",
  };

  const validationSchema = Yup.object({
    sapCode: Yup.string()
      .trim("Please enter a valid sap code")
      .strict(true)
      .required("Enter the SAP Code"),
    grnNumber: Yup.string()
      .trim("Please enter a valid grn number")
      .strict(true)
      .required("Enter the grn number"),
    // uom: Yup.string()
    //   .trim("Please enter a valid uom")
    //   .strict(true)
    //   .required("Enter the uom"),
    batchNumber: Yup.string()
      .trim("Please enter a valid batch number")

      .required("Enter the batch number"),
    storingLocation: Yup.object()
      // .trim("Please enter a valid location")
      // .strict(true)
      .required("Select the Location"),
    totalQty: Yup.number()
      .positive("Please enter a positive number")
      .required("Enter the Total Qty"),
    noOfPrints: Yup.number()
      .positive("Please enter a positive number")
      .required("Enter the number of prints"),
    ShelfLife: Yup.string().required("Select the shelf life"),
    mfgDate: Yup.string().required("Select the mfg date"),
  });

  const [printErrorMsg, setPrintErrorMsg] = useState("");

  const [printSuccessMsg, setPrintSuccessMsg] = useState("");

  const onSubmit = async (values, { resetForm }) => {
    values.materialDescription = materialDescription;
    values.uom = uom;
    values.printerId = printerId;
    values.userId = userId;
    values.userName = userName;
    values.storingLocation = locationSelected.value;

    let res = await stockCutOver(values, jwtToken);

    if (res.status) {
      resetForm();
      setMaterialDescription("");
      setUom("");
      setIsSuccessStockCutOver(true);
      setPrintSuccessMsg(res.data.msg);
    }
    if (!res.status) {
      setPrintErrorMsg(res.data.data.message);
      setIsErrorStockCutOver(true);
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const searchSapCodeHandler = async () => {
    let res = await getMaterialDesc(formik.values.sapCode, jwtToken);
  };

  useEffect(() => {
    setMaterialDescription(
      getMaterialDescReducer?.desc[0]?.materialDescription
    );
    setUom(getMaterialDescReducer?.desc[0]?.uom);
  }, [getMaterialDescReducer]);

  return (
    <div>
      <>
        {/* <DashboardLayout>
          <DashboardNavbar /> */}
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Card sx={{ width: "40%", marginTop: "20px" }}>
            <MDBox ml={2} mt={2}>
              <MDTypography variant="h4" fontWeight="medium">
                Store Cut Over
              </MDTypography>
            </MDBox>

            <hr style={{ marginTop: "0.1rem" }} />

            <MDBox component="form" role="form" onSubmit={formik.handleSubmit}>
              <Grid container>
                <Grid item xs={12}>
                  <MDBox p={1}>
                    <MDTypography
                      variant="h6"
                      style={{ fontSize: 14, fontWeight: 500 }}
                    >
                      SAP Code
                    </MDTypography>
                    <span style={{ display: "flex" }}>
                      <MDInput
                        type="text"
                        name="sapCode"
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.sapCode}
                        error={
                          formik.touched.sapCode &&
                          formik.errors.sapCode &&
                          true
                        }
                      />

                      <Tooltip title="Search SAP Code">
                        <MDButton
                          variant="gradient"
                          color="info"
                          iconOnly
                          type="button"
                          style={{ marginLeft: "0.5rem" }}
                          onClick={searchSapCodeHandler}
                        >
                          <Icon> search </Icon>
                        </MDButton>
                      </Tooltip>
                    </span>
                    {formik.touched.sapCode && formik.errors.sapCode && (
                      <TextError msg={formik.errors.sapCode} />
                    )}
                  </MDBox>
                </Grid>
                <Grid item xs={12}>
                  <MDBox p={1}>
                    <MDTypography
                      variant="h6"
                      style={{ fontSize: 14, fontWeight: 500 }}
                    >
                      Material Description
                    </MDTypography>
                    <MDInput
                      type="text"
                      name="materialDescription"
                      disabled
                      fullWidth
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={materialDescription}
                      error={
                        formik.touched.materialDescription &&
                        formik.errors.materialDescription &&
                        true
                      }
                    />
                    {formik.touched.materialDescription &&
                      formik.errors.materialDescription && (
                        <TextError msg={formik.errors.materialDescription} />
                      )}
                  </MDBox>
                </Grid>
                <Grid item xs={6}>
                  <MDBox p={1}>
                    <MDTypography
                      variant="h6"
                      style={{ fontSize: 14, fontWeight: 500 }}
                    >
                      PO/GRN Number
                    </MDTypography>
                    <MDInput
                      type="text"
                      name="grnNumber"
                      fullWidth
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.grnNumber}
                      error={
                        formik.touched.grnNumber &&
                        formik.errors.grnNumber &&
                        true
                      }
                    />
                    {formik.touched.grnNumber && formik.errors.grnNumber && (
                      <TextError msg={formik.errors.grnNumber} />
                    )}
                  </MDBox>
                </Grid>
                <Grid item xs={6}>
                  <MDBox p={1}>
                    <MDTypography
                      variant="h6"
                      style={{ fontSize: 14, fontWeight: 500 }}
                    >
                      UOM
                    </MDTypography>
                    <MDInput
                      type="text"
                      name="uom"
                      fullWidth
                      disabled
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={uom}
                      error={formik.touched.uom && formik.errors.uom && true}
                    />
                    {formik.touched.uom && formik.errors.uom && (
                      <TextError msg={formik.errors.uom} />
                    )}
                  </MDBox>
                </Grid>

                <Grid item xs={6}>
                  <MDBox p={1}>
                    <MDTypography
                      variant="h6"
                      style={{ fontSize: 14, fontWeight: 500 }}
                    >
                      Batch Number
                    </MDTypography>
                    <MDInput
                      type="text"
                      name="batchNumber"
                      fullWidth
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.batchNumber}
                      error={
                        formik.touched.batchNumber &&
                        formik.errors.batchNumber &&
                        true
                      }
                    />
                    {formik.touched.batchNumber &&
                      formik.errors.batchNumber && (
                        <TextError msg={formik.errors.batchNumber} />
                      )}
                  </MDBox>
                </Grid>
                <Grid item xs={6}>
                  <MDBox p={1}>
                    <MDTypography
                      variant="h6"
                      style={{ fontSize: 14, fontWeight: 500 }}
                    >
                      Total Qty
                    </MDTypography>
                    <MDInput
                      type="number"
                      name="totalQty"
                      fullWidth
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.totalQty}
                      error={
                        formik.touched.totalQty &&
                        formik.errors.totalQty &&
                        true
                      }
                    />
                    {formik.touched.totalQty && formik.errors.totalQty && (
                      <TextError msg={formik.errors.totalQty} />
                    )}
                  </MDBox>
                </Grid>
                <Grid item xs={6}>
                  <MDBox p={1}>
                    <MDTypography
                      variant="h6"
                      style={{ fontSize: 14, fontWeight: 500 }}
                    >
                      No Of Prints
                    </MDTypography>
                    <MDInput
                      type="number"
                      name="noOfPrints"
                      fullWidth
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.noOfPrints}
                      error={
                        formik.touched.noOfPrints &&
                        formik.errors.noOfPrints &&
                        true
                      }
                    />
                    {formik.touched.noOfPrints && formik.errors.noOfPrints && (
                      <TextError msg={formik.errors.noOfPrints} />
                    )}
                  </MDBox>
                </Grid>
                <Grid item xs={6}>
                  <MDBox p={1}>
                    <MDTypography
                      variant="h6"
                      style={{ fontSize: 14, fontWeight: 500 }}
                    >
                      Tote/Hopper/Pallet ID
                    </MDTypography>
                    <MDInput
                      type="number"
                      name="palletId"
                      fullWidth
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.palletId}
                      error={
                        formik.touched.palletId &&
                        formik.errors.palletId &&
                        true
                      }
                    />
                    {formik.touched.palletId && formik.errors.palletId && (
                      <TextError msg={formik.errors.palletId} />
                    )}
                  </MDBox>
                </Grid>
                <Grid item xs={6}>
                  <MDBox p={1}>
                    <MDTypography
                      variant="h6"
                      style={{ fontSize: 14, fontWeight: 500 }}
                    >
                      Mfg Date
                    </MDTypography>
                    <MDInput
                      type="date"
                      name="mfgDate"
                      fullWidth
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.mfgDate}
                      error={
                        formik.touched.mfgDate && formik.errors.mfgDate && true
                      }
                    />
                    {formik.touched.mfgDate && formik.errors.mfgDate && (
                      <TextError msg={formik.errors.mfgDate} />
                    )}
                  </MDBox>
                </Grid>
                <Grid item xs={6}>
                  <MDBox p={1}>
                    <MDTypography
                      variant="h6"
                      style={{ fontSize: 14, fontWeight: 500 }}
                    >
                      Shelf Life
                    </MDTypography>
                    <MDInput
                      type="date"
                      name="ShelfLife"
                      fullWidth
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.ShelfLife}
                      error={
                        formik.touched.ShelfLife &&
                        formik.errors.ShelfLife &&
                        true
                      }
                    />
                    {formik.touched.ShelfLife && formik.errors.ShelfLife && (
                      <TextError msg={formik.errors.ShelfLife} />
                    )}
                  </MDBox>
                </Grid>
                <Grid item xs={6}>
                  <MDBox p={1}>
                    <MDTypography
                      variant="h6"
                      style={{ fontSize: 14, fontWeight: 500 }}
                    >
                      Location
                    </MDTypography>
                    <Select
                      isClearable
                      className="select-css"
                      placeholder="Select location..."
                      name="storingLocation"
                      value={locationSelected}
                      options={warehouseOptions}
                      onChange={(selectedOption) => {
                        handleLocationChange(selectedOption);
                        formik.setFieldValue("storingLocation", selectedOption);
                      }}
                    />
                    {formik.errors.storingLocation && (
                      <TextError msg={formik.errors.storingLocation} />
                    )}
                  </MDBox>
                </Grid>
              </Grid>
              <MDBox className="mt-3">
                <Collapse in={isErrorStockCutOver}>
                  <Alert
                    severity="error"
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setIsErrorStockCutOver(false);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    {/* Could not print the Under Test label! */}
                    {printErrorMsg}
                  </Alert>
                </Collapse>
                <Collapse in={isSuccessStockCutOver}>
                  <Alert
                    severity="success"
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setIsSuccessStockCutOver(false);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    {printSuccessMsg}
                  </Alert>
                </Collapse>
              </MDBox>

              <MDBox display="flex" style={{ float: "right" }} p={1}>
                {printerId == undefined && (
                  <MDTypography
                    style={{
                      color: "red",
                      marginRight: "10px",
                      fontSize: "15px",
                      marginTop: "5px",
                    }}
                  >
                    Please configure the Printer!
                  </MDTypography>
                )}
                <MDButton
                  color="success"
                  type="submit"
                  size="small"
                  disabled={
                    (!formik.isValid && !printStockCutOver) ||
                    (!materialDescription && !printStockCutOver) ||
                    printerId == undefined ||
                    !formik.isValid ||
                    !materialDescription ||
                    !uom
                  }
                >
                  Print
                </MDButton>
              </MDBox>
            </MDBox>
          </Card>
        </div>
        <br />
        <br />
        <br />

        {/* </DashboardLayout> */}
      </>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    getMaterialDescReducer: state.getMaterialDescReducer,
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    warehouses: state.warehouses,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMaterialDesc: (materialCode, jwtToken) =>
      dispatch(getMaterialDesc(materialCode, jwtToken)),
    stockCutOver: (payload, jwtToken) =>
      dispatch(stockCutOver(payload, jwtToken)),
    getWarehouses: (warehouseType, jwtToken) =>
      dispatch(getWarehouses(warehouseType, jwtToken)),
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StockCutOver);
