import {
  GET_PIVTRACKING_FAILURE,
  GET_PIVTRACKING_REQUEST,
  GET_PIVTRACKING_SUCCESS,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  GET_STORE_LOCATION_REQUEST,
  GET_STORE_LOCATION_SUCCESS,
  GET_STORE_LOCATION_FAILURE,
  REPRINT_PIVTRACKING_REQUEST,
  REPRINT_PIVTRACKING_SUCCESS,
  REPRINT_PIVTRACKING_FAILURE,
  GET_PIVTRACKING_FORREPORTS_REQUEST,
  GET_PIVTRACKING_FORREPORTS_SUCCESS,
  GET_PIVTRACKING_FORREPORTS_FAILURE,
  CREATE_MATERIAL_RETURN_PROCESS_REQUEST,
  CREATE_MATERIAL_RETURN_PROCESS_SUCCESS,
  CREATE_MATERIAL_RETURN_PROCESS_FAILURE,
  UPDATE_TO_LOCATION_REQUEST,
  UPDATE_TO_LOCATION_SUCCESS,
  UPDATE_TO_LOCATION_FAILURE,
} from "./pivTrackingTypes";
import axios from "axios";
import Config from "../../config/index";
let baseUrl = Config.baseUrl;

const getPivTrackingRequest = () => {
  return {
    type: GET_PIVTRACKING_REQUEST,
  };
};

const getPivTrackingSuccess = (payload) => {
  return {
    type: GET_PIVTRACKING_SUCCESS,
    payload: payload,
  };
};

const getPivTrackingFailure = (error) => {
  return {
    type: GET_PIVTRACKING_FAILURE,
    payload: error,
  };
};

const getPivTracking = (pageNo, rowsPerPage, search, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getPivTrackingRequest());
    let url = `${baseUrl}/piv_tracking/get?page_number=${
      pageNo + 1
    }&rows_per_page=${rowsPerPage}&search=${search ? search : ""}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getPivTrackingSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getPivTrackingFailure(err.response));
      });
  };
};

const updateUserRequest = () => {
  return {
    type: UPDATE_USER_REQUEST,
  };
};

const updateUserSuccess = (payload) => {
  return {
    type: UPDATE_USER_SUCCESS,
    payload: payload,
  };
};

const updateUserFaliure = (error) => {
  return {
    type: UPDATE_USER_FAILURE,
    payload: error,
  };
};

const updateUser = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };

  return (dispatch) => {
    dispatch(updateUserRequest());
    let url = `${baseUrl}/piv_tracking/update_user`;
    return axios
      .put(url, payload, headers)
      .then((response) => {
        dispatch(updateUserSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(updateUserFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const getStoreLocationByMaterialCodeRequest = () => {
  return {
    type: GET_STORE_LOCATION_REQUEST,
  };
};

const getStoreLocationByMaterialCodeSuccess = (payload) => {
  return {
    type: GET_STORE_LOCATION_SUCCESS,
    payload: payload,
  };
};

const getStoreLocationByMaterialCodeFailure = (error) => {
  return {
    type: GET_STORE_LOCATION_FAILURE,
    payload: error,
  };
};

const getStoreLocationByMaterialCode = (materialCode, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getStoreLocationByMaterialCodeRequest());
    let url = `${baseUrl}/storing_location_by_material/get?materialCode=${materialCode}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getStoreLocationByMaterialCodeSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getStoreLocationByMaterialCodeFailure(err.response));
      });
  };
};

const reprintPivTrackingRequest = () => {
  return {
    type: REPRINT_PIVTRACKING_REQUEST,
  };
};

const reprintPivTrackingSuccess = (payload) => {
  return {
    type: REPRINT_PIVTRACKING_SUCCESS,
    payload: payload,
  };
};

const reprintPivTrackingFailure = (error) => {
  return {
    type: REPRINT_PIVTRACKING_FAILURE,
    payload: error,
  };
};

const reprintPivTracking = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(reprintPivTrackingRequest());
    let url = `${baseUrl}/sub_store_issue_label/reprint`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(reprintPivTrackingSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(reprintPivTrackingFailure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const getPivTrackingForReportsRequest = () => {
  return {
    type: GET_PIVTRACKING_FORREPORTS_REQUEST,
  };
};

const getPivTrackingForReportsSuccess = (payload) => {
  return {
    type: GET_PIVTRACKING_FORREPORTS_SUCCESS,
    payload: payload,
  };
};

const getPivTrackingForReportsFailure = (error) => {
  return {
    type: GET_PIVTRACKING_FORREPORTS_FAILURE,
    payload: error,
  };
};

const getPivTrackingForReports = (pageNo, rowsPerPage, search, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getPivTrackingForReportsRequest());
    let url = `${baseUrl}/piv_tracking/get?page_number=${
      pageNo + 1
    }&rows_per_page=${rowsPerPage}&search=${search ? search : ""}&download=1`;
    return axios
      .get(url, headers)
      .then((response) => {
        dispatch(getPivTrackingForReportsSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(getPivTrackingForReportsFailure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const createMaterialReturnProcessRequest = () => {
  return {
    type: CREATE_MATERIAL_RETURN_PROCESS_REQUEST,
  };
};

const createMaterialReturnProcessSuccess = (payload) => {
  return {
    type: CREATE_MATERIAL_RETURN_PROCESS_SUCCESS,
    payload: payload,
  };
};

const createMaterialReturnProcessFaliure = (error) => {
  return {
    type: CREATE_MATERIAL_RETURN_PROCESS_FAILURE,
    payload: error,
  };
};

const createMaterialReturnProcess = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };

  return (dispatch) => {
    dispatch(createMaterialReturnProcessRequest());
    let url = `${baseUrl}/material_return/create_return_req`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(createMaterialReturnProcessSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(createMaterialReturnProcessFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const updateToLocationRequest = () => {
  return {
    type: UPDATE_TO_LOCATION_REQUEST,
  };
};

const updateToLocationSuccess = (payload) => {
  return {
    type: UPDATE_TO_LOCATION_SUCCESS,
    payload: payload,
  };
};

const updateToLocationFaliure = (error) => {
  return {
    type: UPDATE_TO_LOCATION_FAILURE,
    payload: error,
  };
};

const updateToLocation = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };

  return (dispatch) => {
    dispatch(updateToLocationRequest());
    let url = `${baseUrl}/piv_tracking/update_tolocation`;
    return axios
      .put(url, payload, headers)
      .then((response) => {
        dispatch(updateToLocationSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(updateToLocationFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};

export {
  getPivTracking,
  updateUser,
  getStoreLocationByMaterialCode,
  reprintPivTracking,
  getPivTrackingForReports,
  createMaterialReturnProcess,
  updateToLocation,
};
