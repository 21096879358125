export const GET_PIVTRACKING_REQUEST = "GET_PIVTRACKING_REQUEST";
export const GET_PIVTRACKING_SUCCESS = "GET_PIVTRACKING_SUCCESS";
export const GET_PIVTRACKING_FAILURE = "GET_PIVTRACKING_FAILURE";

export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";

export const GET_STORE_LOCATION_REQUEST = "GET_STORE_LOCATION_REQUEST";
export const GET_STORE_LOCATION_SUCCESS = "GET_STORE_LOCATION_SUCCESS";
export const GET_STORE_LOCATION_FAILURE = "GET_STORE_LOCATION_FAILURE";

export const REPRINT_PIVTRACKING_REQUEST = "REPRINT_PIVTRACKING_REQUEST";
export const REPRINT_PIVTRACKING_SUCCESS = "REPRINT_PIVTRACKING_SUCCESS";
export const REPRINT_PIVTRACKING_FAILURE = "REPRINT_PIVTRACKING_FAILURE";

export const GET_PIVTRACKING_FORREPORTS_REQUEST =
  "GET_PIVTRACKING_FORREPORTS_REQUEST";
export const GET_PIVTRACKING_FORREPORTS_SUCCESS =
  "GET_PIVTRACKING_FORREPORTS_SUCCESS";
export const GET_PIVTRACKING_FORREPORTS_FAILURE =
  "GET_PIVTRACKING_FORREPORTS_FAILURE";

export const CREATE_MATERIAL_RETURN_PROCESS_REQUEST =
  "CREATE_MATERIAL_RETURN_PROCESS_REQUEST";
export const CREATE_MATERIAL_RETURN_PROCESS_SUCCESS =
  "CREATE_MATERIAL_RETURN_PROCESS_SUCCESS";
export const CREATE_MATERIAL_RETURN_PROCESS_FAILURE =
  "CREATE_MATERIAL_RETURN_PROCESS_FAILURE";

export const UPDATE_TO_LOCATION_REQUEST = "UPDATE_TO_LOCATION_REQUEST";
export const UPDATE_TO_LOCATION_SUCCESS = "UPDATE_TO_LOCATION_SUCCESS";
export const UPDATE_TO_LOCATION_FAILURE = "UPDATE_TO_LOCATION_FAILURE";
