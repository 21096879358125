/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

// Formik and Yup
import { useFormik } from "formik";
import * as Yup from "yup";

// Utils component
import TextError from "utils/TextError";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Dashboard components
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDCard from "components/MDCard";
import MDInput from "components/MDInput";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";

// React-Select component and styling
import Select from "react-select";
import "../../../index.css";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

import { CSVLink } from "react-csv";

// Functions from store
import {
  getWarehouseOccupancy,
  getSubModulesByRoleId,
  downloadWarehouseOccupancy,
  editExpiry,
  documentUpload,
  updateQIStatus,
} from "../../../store";

//  import aws or uuid
import AWS from "aws-sdk";
import { v4 } from "uuid";

// Cookies
import Cookies from "universal-cookie";

// Config file
import Config from "../../../config/index";
import { addMinutes, format } from "date-fns";

let desLength = Config.descriptionLength;
const cookies = new Cookies();

const styleForEditExpiry = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  boxShadow: 24,
  p: 0,
  pt: 3,
};

const styleForDownloadModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  color: "black",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const styleForLocationsModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 380, sm: 380, md: 500, lg: 900, xl: 900 },
  height: "fit-content",
  maxHeight: { xs: 340, sm: 340, md: 440, lg: 600, xl: 600 },
  overflow: "scroll",
  color: "black",
  boxShadow: 24,
  p: 2,
  pt: 3,
};

const styleForDocument = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 380, sm: 380, md: 600, lg: 700, xl: 700 },
  overflow: "auto",
  height: "90%",
  boxShadow: 24,
  p: 1,
  pt: 3,
};

const deleteStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 580,
  boxShadow: 24,
  p: 2,
  pt: 3,
};

const WarehouseOccupancy = ({
  getWarehouseOccupancy,
  getWarehouseOccupancyState,
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
  downloadWarehouseOccupancy,
  downloadWarehouseOccupancyState,
  editExpiry,
  documentUpload,
  updateQIStatus,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");
  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;
  let userId = loginDetails?.data.userId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let reportsModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "07390a6c-3bf9-4474-9396-b7d6192eb805"
  );

  let viewReportsAccess = reportsModule
    ? reportsModule.actionId.includes("3a0fd2ad-d747-4099-aaa6-7b79d92c9805")
    : null;

  let editExpiryAccess = reportsModule
    ? reportsModule.actionId.includes("83f6f23e-c083-4a82-9415-ee1bd9298994")
    : null;

  let updateQIStatusAccess = reportsModule
    ? reportsModule.actionId.includes("60507328-d17c-4ea6-958b-804ebf075c78")
    : null;

  const [pageNo, setPageNo] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [searchObject, setSearchObject] = useState(JSON.stringify({}));
  const [paginationObj, setPaginationObj] = useState({});
  const [loading, setLoading] = useState(true);

  const importPageNo = (pageIndex) => {
    setPageNo(pageIndex === -1 ? pageNo : pageIndex);
  };

  const importRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
  };

  const importSearch = (globalFilter) => {
    setSearchObject(globalFilter ? JSON.stringify(globalFilter) : "");
  };

  useEffect(() => {
    getWarehouseOccupancy(pageNo, rowsPerPage, searchObject, jwtToken);
  }, [pageNo, rowsPerPage, searchObject]);

  const statusOptions = [
    {
      label: "All Status",
      value: "",
    },
    {
      label: "Unrestricted-use",
      value: "F",
    },
    {
      label: "Blocked",
      value: "S",
    },
    {
      label: "Quality Inspection",
      value: "X",
    },
    {
      label: "NA",
      value: "NA",
    },
  ];

  const expiryStatusOptions = [
    {
      label: "All Status",
      value: "",
    },
    {
      label: "Not Expired",
      value: 0,
    },
    {
      label: "Expired",
      value: 1,
    },
  ];

  const [state, setState] = useState({
    columns: [
      { Header: "Document Type", accessor: "documentType" },
      { Header: "Document No", accessor: "documentNo" },
      { Header: "Item Code", accessor: "materialCode" },
      { Header: "Description", accessor: "materialDescription" },
      { Header: "Batch No", accessor: "batchNumber" },
      { Header: "Qty", accessor: "totalQty" },
      { Header: "UID", accessor: "sku" },
      {
        Header: "Expiry",
        accessor: "shelfLife",
        searchType: "date",
        Cell: ({ value }) => {
          return value
            ? format(
                addMinutes(
                  new Date(value),
                  new Date(value).getTimezoneOffset()
                ),
                "dd/MM/yyyy, HH:mm"
              )
            : "NA";
        },
      },
      {
        Header: "Grn Date",
        accessor: "grnDate",
        searchType: "date",
        Cell: ({ value }) => {
          return value
            ? format(
                addMinutes(
                  new Date(value),
                  new Date(value)?.getTimezoneOffset()
                ),
                "dd/MM/yyyy, HH:mm"
              )
            : "NA";
        },
      },
      {
        Header: "Expiry Status",
        accessor: "isExpired",
        searchType: "select",
        selectOptions: expiryStatusOptions,
        placeholder: "All Status",
        align: "center",
      },
      { Header: "Revalidation", accessor: "reValidation" },
      { Header: "Indicator", accessor: "indicator" },
      { Header: "Warehouse", accessor: "warehouseCode" },
      { Header: "Zone", accessor: "zoneName" },
      { Header: "Location", accessor: "locationName" },
      { Header: "Asset", accessor: "asset" },
      { Header: "Reprint Count", accessor: "reprintCount" },

      {
        Header: "Status",
        accessor: "stockType",
        searchType: "select",
        selectOptions: statusOptions,
        placeholder: "All Status",
      },
    ],
    rows: [],
  });
  const { columns, rows } = state;

  useEffect(() => {
    let tempReports = [];
    let data = getWarehouseOccupancyState.warehouseOccupancy.data
      ? getWarehouseOccupancyState.warehouseOccupancy.data
      : [];

    setLoading(getWarehouseOccupancyState.loading);
    setPaginationObj(
      getWarehouseOccupancyState?.warehouseOccupancy?.pagination
    );

    data.map((singleData) => {
      let des = singleData?.materialDescription;
      let len = desLength;

      let validation =
        singleData.isBatchManaged === 0 && singleData.isExpired === 1;

      const dataObject = {
        documentType:
          singleData.isExpired === 1 ? (
            <button className="status-error" style={{ width: "5.5rem" }}>
              {singleData.documentType}
            </button>
          ) : (
            singleData.documentType
          ),
        documentNo: singleData.documentNo,
        batchNumber: singleData.batchNumber,
        reValidation: singleData.reValidation,
        materialCode: singleData.materialCode,
        materialDescription: des ? des.substring(0, len) : "",
        totalQty: `${singleData.totalQty} ${singleData.uom}`,
        sku: singleData.sku,
        shelfLife: singleData.shelfLife,
        grnDate: singleData?.grnDate,
        reprintCount: singleData?.reprintCount,
        isExpired: (
          <span style={{ display: "flex" }}>
            <button
              className={
                (singleData.isExpired === 1 && `status-error`) ||
                (singleData.isExpired === 0 && `status-success`)
              }
              style={{
                width: "5.5rem",
                marginRight: "1rem",
                // marginLeft: "2rem",
              }}
            >
              {(singleData.isExpired === 1 && `Expired`) ||
                (singleData.isExpired === 0 && `Not Expired`)}
            </button>
            <Tooltip title="Edit Expiry">
              <MDButton
                // 1 && 0
                disabled={!validation || !editExpiryAccess}
                color="warning"
                variant="gradient"
                type="button"
                iconOnly
                size="small"
                onClick={() => {
                  handleOpenEditExpiryModal(singleData);
                }}
              >
                <Icon> edit_icon </Icon>
              </MDButton>
            </Tooltip>

            <Tooltip title="View" style={{ marginLeft: "0.75rem" }}>
              <MDButton
                disabled={singleData?.revalidationInfo?.length < 1}
                color="info"
                variant="gradient"
                type="button"
                iconOnly
                size="small"
                onClick={() => {
                  setLocationsData(singleData?.revalidationInfo);
                  handleOpenLocationsModal(singleData);
                }}
              >
                <Icon> visibility_icon </Icon>
              </MDButton>
            </Tooltip>
          </span>
        ),
        indicator: singleData.indicator,
        warehouseCode: singleData.warehouseCode,
        zoneName: singleData.zoneName,
        locationName: singleData.locationName,
        asset: singleData.asset,

        stockType: (
          <span style={{ display: "flex" }}>
            {singleData.stockType}

            <Tooltip title="Update Status">
              <MDButton
                // 1 && 0
                disabled={
                  singleData.stockType == "Unrestricted-Use" ||
                  singleData.isExpired === 1 ||
                  !updateQIStatusAccess
                }
                color="success"
                variant="gradient"
                type="button"
                iconOnly
                size="small"
                onClick={() => {
                  handleOpenUpdateQIStatusModal(singleData);
                }}
                style={{ marginLeft: "10px" }}
              >
                <Icon> edit_icon </Icon>
              </MDButton>
            </Tooltip>
          </span>
        ),
        // action: (
        //   <Tooltip title="Edit Expiry">
        //     <MDButton
        //       disabled={
        //         (singleData.isBatchManaged === 1 &&
        //           singleData.isExpired === 0) ||
        //         !editExpiryAccess
        //       }
        //       color="success"
        //       variant="gradient"
        //       type="button"
        //       iconOnly
        //       size="small"
        //       onClick={() => {
        //         handleOpenEditExpiryModal(singleData);
        //       }}
        //     >
        //       <Icon> edit_icon </Icon>
        //     </MDButton>
        //   </Tooltip>
        // ),
      };
      tempReports.push(dataObject);
    });
    viewReportsAccess && setState({ ...state, rows: tempReports });
  }, [getWarehouseOccupancyState]);

  const [loadingData, setLoadingData] = useState(false);
  const [downloadData, setDownloadData] = useState([]);

  useEffect(() => {
    let tempReports = [];
    let data = downloadWarehouseOccupancyState.warehouseOccupancy.data
      ? downloadWarehouseOccupancyState.warehouseOccupancy.data
      : [];

    data.map((singleData) => {
      let des = singleData?.materialDescription;
      let len = desLength;
      const formatDate = singleData.shelfLife
        ? new Date(singleData.shelfLife)
        : "";

      const formatDateForGrn = singleData.grnDate
        ? new Date(singleData.grnDate)
        : "";

      const dataObject = {
        documentType: singleData.documentType,
        documentNo: singleData.documentNo,
        batchNumber: singleData.batchNumber,
        reValidation: singleData.reValidation,
        materialCode: singleData.materialCode,
        materialDescription: des ? des.substring(0, len) : "",
        totalQty: `${singleData.totalQty} ${singleData.uom}`,
        sku: singleData.sku,
        reprintCount: singleData.reprintCount,
        shelfLife: singleData.shelfLife
          ? format(
              addMinutes(formatDate, formatDate.getTimezoneOffset()),
              "dd/MM/yyyy, HH:mm"
            )
          : "",
        grnDate: singleData.grnDate
          ? format(
              addMinutes(
                formatDateForGrn,
                formatDateForGrn.getTimezoneOffset()
              ),
              "dd/MM/yyyy, HH:mm"
            )
          : "",
        isExpired: singleData.isExpired === 0 ? "Not Expired" : "Expired",
        indicator: singleData.indicator,
        warehouseCode: singleData.warehouseCode,
        zoneName: singleData.zoneName,
        locationName: singleData.locationName,
        asset: singleData.asset,
        stockType: singleData.stockType,
      };
      tempReports.push(dataObject);
    });
    setDownloadData(tempReports);
  }, [downloadWarehouseOccupancyState]);

  const headers = [
    { label: "Document Type", key: "documentType" },
    { label: "Document No", key: "documentNo" },
    { label: "Item Code", key: "materialCode" },
    { label: "Description", key: "materialDescription" },
    { label: "Batch No", key: "batchNumber" },
    { label: "Qty", key: "totalQty" },
    { label: "UID", key: "sku" },
    { label: "Expiry", key: "shelfLife", searchType: "date" },
    { label: "Expiry Status", key: "isExpired" },
    { label: "Revalidation", key: "reValidation" },
    { label: "Indicator", key: "indicator" },
    { label: "Warehouse", key: "warehouseCode" },
    { label: "Zone", key: "zoneName" },
    { label: "Location", key: "locationName" },
    { label: "Asset", key: "asset" },
    { label: "Reprint Count", key: "reprintCount" },
    { label: "Status", key: "stockType" },
  ];

  const csvLink = {
    filename: "Warehouse Occupancy.csv",
    headers: headers,
    data: downloadData,
  };

  const [openDownloadModal, setOpenDownloadModal] = useState(false);
  const [disableDownloadButton, setDisableDownloadButton] = useState(false);
  const [modalMsg, setModalMsg] = useState("");

  const handleOpenDownloadModal = (data = {}) => {
    setOpenDownloadModal(true);
  };

  const handleCloseDownloadModal = () => {
    setDisableDownloadButton(false);
    setOpenDownloadModal(false);
  };

  const downloadHandler = async () => {
    setLoadingData(true);

    let res = await downloadWarehouseOccupancy(
      pageNo,
      rowsPerPage,
      searchObject,
      jwtToken
    );

    if (res.status) {
      setModalMsg("Ready for download!");
      setLoadingData(false);
      handleOpenDownloadModal();
    }
    if (!res.status) {
      setModalMsg("Could not prepare the data for download!");
      setLoadingData(false);
      setDisableDownloadButton(true);
      handleOpenDownloadModal();
    }
  };

  const uploadHandler = async () => {
    // setLoadingData(true);

    let data = new FormData();

    data.append("data", imageFile);

    let res = await documentUpload(data, jwtToken);

    if (res) {
      // setLoadingData(false);

      setDocument(res.data.fileUrl);
    }
    if (!res.status) {
      // setLoadingData(false);
    }
  };

  // const daysOptions = [
  //   {
  //     label: "15 Days",
  //     value: 15,
  //   },
  //   {
  //     label: "30 Days",
  //     value: 30,
  //   },
  //   {
  //     label: "45 Days",
  //     value: 45,
  //   },
  //   {
  //     label: "60 Days",
  //     value: 60,
  //   },
  // ];

  // const [daysSelected, setDaysSelected] = useState("");
  // const handleDaysChange = (selectedDays) => {
  //   setDaysSelected(selectedDays);
  // };

  const [isErrorEditExpiry, setIsErrorEditExpiry] = useState(false);
  const [isSuccessEditExpiry, setIsSuccessEditExpiry] = useState(false);

  useEffect(() => {
    if (isErrorEditExpiry) {
      setTimeout(() => {
        setIsErrorEditExpiry(false);
      }, 3000);
    }
  }, [isErrorEditExpiry]);

  useEffect(() => {
    if (isSuccessEditExpiry) {
      setTimeout(() => {
        setIsSuccessEditExpiry(false);
      }, 3000);
    }
  }, [isSuccessEditExpiry]);

  const [openEditExpiryModal, setOpenEditExpiryModal] = useState(false);

  const [singleDataForEditExpiry, setSingleDataForEditExpiry] = useState({});

  const handleOpenEditExpiryModal = (data = {}) => {
    setOpenEditExpiryModal(true);
    setSingleDataForEditExpiry(data);
  };

  const handleCloseEditExpiryModal = () => {
    setOpenEditExpiryModal(false);
    // setDaysSelected("");
  };

  const initialValueForEditExpiry = {
    noOfDays: "",
    url: "",
  };

  const validationSchemaEditExpiry = Yup.object({
    noOfDays: Yup.number().required("Enter a valid number!"),
  });

  const [editExpiryErrorMsg, setEditExpiryErrorMsg] = useState("");

  const onSubmitForEditExpiry = async (values, { resetForm }) => {
    let payload = {
      ...singleDataForEditExpiry,
      noOfDays: +values.noOfDays,
      userId: userId,
      url: document,
    };

    let res = await editExpiry(payload, jwtToken);

    if (res.status) {
      setIsSuccessEditExpiry(true);
      getWarehouseOccupancy(pageNo, rowsPerPage, searchObject, jwtToken);
      resetForm();

      setTimeout(() => {
        handleCloseEditExpiryModal();
      }, 2250);
    }
    if (!res.status) {
      setEditExpiryErrorMsg(res.data.data.message);
      setIsErrorEditExpiry(true);
    }
  };

  const formikForEditExpiry = useFormik({
    initialValues: initialValueForEditExpiry,
    validationSchema: validationSchemaEditExpiry,
    onSubmit: onSubmitForEditExpiry,
  });

  const handleChangeForDocument = (event) => {
    setImageFile(event.target.files[0]);
  };
  const [imageFile, setImageFile] = useState({});

  const [flag, setFlag] = useState(false);

  useEffect(() => {
    if (imageFile !== "") uploadHandler();
  }, [imageFile]);

  useEffect(() => {
    if (imageFile.type == "image/jpeg" || imageFile.type == "image/png") {
      setFlag(true);
    } else {
      setFlag(false);
    }
  }, [imageFile]);

  const [document, setDocument] = useState("");

  const [documentForView, setDocumentForView] = useState("");

  const [openViewDocumentModal, setOpenViewDocumentModal] = useState(false);

  const handleOpenViewDocumentModal = (data = {}) => {
    setDocumentForView(data.url);
    setOpenViewDocumentModal(true);
  };

  const handleCloseViewDocumentModal = () => {
    setOpenViewDocumentModal(false);
  };

  const [errorMsgFromApi, setErrorMsgFromApi] = useState("");

  const [errorMsgForLineItemFromApi, setErrorMsgForLineItemFromApi] =
    useState("");

  const [locationsModal, setOpenLocationsModal] = useState(false);
  const [locationsData, setLocationsData] = useState([]);

  const handleOpenLocationsModal = () => {
    setOpenLocationsModal(true);
  };

  const handleCloseLocationsModal = () => {
    setOpenLocationsModal(false);
  };

  const [locationsState, setLocationsState] = useState({
    columns: [
      { Header: "No of days", accessor: "noOfDays" },
      { Header: "url", accessor: "url" },
      { Header: "cd", accessor: "cd" },
      { Header: "Document", accessor: "action" },
    ],
    rows: [],
  });

  const { columns: locationsColumns, rows: locationsRows } = locationsState;

  useEffect(() => {
    let tempLocationsObj = [];

    let data = locationsData ? locationsData : [];

    data.map((data) => {
      const locationObj = {
        noOfDays: data?.noOfDays,
        url: data?.url,
        cd: data?.cd,
        action: (
          <Tooltip title="View Documents" style={{ marginLeft: "0.75rem" }}>
            <MDButton
              color="info"
              variant="gradient"
              type="button"
              iconOnly
              size="small"
              onClick={() => {
                handleOpenViewDocumentModal(data);
              }}
            >
              <Icon> visibility_icon </Icon>
            </MDButton>
          </Tooltip>
        ),
      };

      tempLocationsObj.push(locationObj);
    });

    setLocationsState({ ...locationsState, rows: tempLocationsObj });
  }, [locationsData]);

  const [updateQIStatusErrorMsg, setUpdateQIStatusErrorMsg] = useState("");

  const [isErrorUpdateQIStatus, setIsErrorUpdateQIStatus] = useState(false);
  const [isSuccessUpdateQIStatus, setIsSuccessUpdateQIStatus] = useState(false);

  useEffect(() => {
    if (isErrorUpdateQIStatus) {
      setTimeout(() => {
        setIsErrorUpdateQIStatus(false);
      }, 3000);
    }
  }, [isErrorUpdateQIStatus]);

  useEffect(() => {
    if (isSuccessUpdateQIStatus) {
      setTimeout(() => {
        setIsSuccessUpdateQIStatus(false);
      }, 3000);
    }
  }, [isSuccessUpdateQIStatus]);

  const [openUpdateQIStatusModal, setOpenUpdateQIStatusModal] = useState(false);

  const handleOpenUpdateQIStatusModal = (data) => {
    setSingleDataForEditExpiry(data);
    setOpenUpdateQIStatusModal(true);
  };
  const handleCloseUpdateQIStatusModal = () => {
    setOpenUpdateQIStatusModal(false);
  };

  const updateQIStatusHandler = async () => {
    let payload = {
      documentNo: singleDataForEditExpiry?.documentNo,
      grnLineNumber: singleDataForEditExpiry?.grnLineNumber,
      materialCode: singleDataForEditExpiry?.materialCode,
      stockType:
        (singleDataForEditExpiry.stockType == "Quality Inspection" && "X") ||
        (singleDataForEditExpiry.stockType == "Blocked" && "S"),
      userId: userId,
    };

    let response = await updateQIStatus(payload, jwtToken);

    if (response.status) {
      getWarehouseOccupancy(pageNo, rowsPerPage, searchObject, jwtToken);
      setIsSuccessUpdateQIStatus(true);
      setTimeout(() => {
        handleCloseUpdateQIStatusModal();
      }, 2250);
    }
    if (!response.status) {
      setUpdateQIStatusErrorMsg(response?.data?.data?.message);
      setIsErrorUpdateQIStatus(true);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              {viewReportsAccess && (
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  p={3}
                  // mb={-6}
                >
                  <MDBox color="text">
                    {/* <CSVLink {...csvLink}> */}
                    <Tooltip title="Download">
                      <MDButton
                        style={{ marginRight: "1rem", marginLeft: "10px" }}
                        type="button"
                        variant="outlined"
                        color="error"
                        iconOnly
                        onClick={() => {
                          downloadHandler();
                        }}
                      >
                        <Icon>download</Icon>
                      </MDButton>
                    </Tooltip>
                    {/* </CSVLink> */}
                  </MDBox>
                </MDBox>
              )}

              <MDBox mt={viewReportsAccess ? -3 : 0}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  entriesPerPage={{
                    defaultValue: 30,
                    entries: [30, 50, 75, 100],
                  }}
                  showTotalEntries={true}
                  noEndBorder
                  options={{ draggable: false }}
                  serverSidePagination={true}
                  paginationObj={paginationObj}
                  pageNoValue={pageNo}
                  rowsPerPageValue={rowsPerPage}
                  pageNoProp={importPageNo}
                  rowsPerPageProp={importRowsPerPage}
                  searchProp={importSearch}
                />
              </MDBox>

              <Modal
                open={openDownloadModal}
                onClose={handleCloseDownloadModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={styleForDownloadModal}>
                  <MDTypography>{modalMsg}</MDTypography>
                  <MDBox mt={2}>
                    <MDButton
                      style={{ width: "48%", marginRight: "0.5rem" }}
                      color="info"
                      variant="outlined"
                      onClick={handleCloseDownloadModal}
                    >
                      Cancel
                    </MDButton>
                    {!disableDownloadButton && (
                      <CSVLink {...csvLink}>
                        <MDButton
                          style={{ width: "48%", marginRight: "0.5rem" }}
                          type="button"
                          variant="outlined"
                          color="error"
                        >
                          Download
                        </MDButton>
                      </CSVLink>
                    )}
                  </MDBox>
                </MDCard>
              </Modal>

              <Modal
                open={locationsModal}
                onClose={handleCloseLocationsModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={styleForLocationsModal}>
                  <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                    <MDTypography variant="h4" fontWeight="medium" flexGrow={1}>
                      Locations
                    </MDTypography>
                    <Tooltip title="Close">
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        onClick={handleCloseLocationsModal}
                        style={{
                          background: "whitesmoke",
                          color: "black",
                          borderRadius: 5,
                          marginLeft: "-55px",
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  </MDBox>

                  <MDBox pt={1} pb={3} px={3}>
                    <DataTable
                      table={{
                        columns: locationsColumns,
                        rows: locationsRows,
                      }}
                      isSorted={true}
                      entriesPerPage={{
                        defaultValue: 30,
                        entries: [30, 50, 75, 100],
                      }}
                      showTotalEntries={true}
                      noEndBorder
                      options={{ draggable: false }}
                    />
                  </MDBox>
                </MDCard>
              </Modal>
              <Modal
                open={openEditExpiryModal}
                onClose={handleCloseEditExpiryModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={styleForEditExpiry}>
                  <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                    <MDTypography variant="h4" fontWeight="medium" flexGrow={1}>
                      Edit Expiry
                    </MDTypography>
                    <Tooltip title="Close">
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        onClick={handleCloseEditExpiryModal}
                        style={{
                          background: "whitesmoke",
                          color: "black",
                          borderRadius: 5,
                          marginLeft: "-55px",
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  </MDBox>
                  <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                  <MDBox pt={1} pb={3} px={3}>
                    <MDBox
                      component="form"
                      role="form"
                      onSubmit={formikForEditExpiry.handleSubmit}
                    >
                      <MDBox>
                        <MDBox mb={2}>
                          <MDTypography
                            variant="h6"
                            style={{ fontWeight: 500 }}
                          >
                            Document no:-{" "}
                            <span style={{ fontWeight: 400 }}>
                              {singleDataForEditExpiry?.documentNo}
                            </span>
                          </MDTypography>
                          <MDTypography
                            variant="h6"
                            style={{ fontWeight: 500 }}
                          >
                            UID:-{" "}
                            <span style={{ fontWeight: 400 }}>
                              {singleDataForEditExpiry?.sku}
                            </span>
                          </MDTypography>
                          <MDTypography
                            variant="h6"
                            style={{ fontWeight: 500 }}
                          >
                            Mfg Date:-{" "}
                            <span style={{ fontWeight: 400 }}>
                              {singleDataForEditExpiry.mfgDate
                                ? format(
                                    addMinutes(
                                      new Date(singleDataForEditExpiry.mfgDate),
                                      new Date(
                                        singleDataForEditExpiry.mfgDate
                                      ).getTimezoneOffset()
                                    ),
                                    "dd/MM/yyyy, HH:mm"
                                  )
                                : ""}
                            </span>
                          </MDTypography>
                          <MDTypography
                            variant="h6"
                            style={{ fontWeight: 500 }}
                          >
                            Expiry Date:-{" "}
                            <span style={{ fontWeight: 400 }}>
                              {singleDataForEditExpiry.shelfLife
                                ? format(
                                    addMinutes(
                                      new Date(
                                        singleDataForEditExpiry.shelfLife
                                      ),
                                      new Date(
                                        singleDataForEditExpiry.shelfLife
                                      ).getTimezoneOffset()
                                    ),
                                    "dd/MM/yyyy, HH:mm"
                                  )
                                : ""}
                            </span>
                          </MDTypography>
                        </MDBox>
                        {/* <MDBox mb={2}>
                          <MDTypography
                            variant="h6"
                            style={{ fontWeight: 500 }}
                          >
                            No of Days
                          </MDTypography>

                          <Select
                            isClearable
                            className="select-css"
                            placeholder="Select days..."
                            name="noOfDays"
                            value={daysSelected}
                            options={daysOptions}
                            onChange={(selectedOption) => {
                              handleDaysChange(selectedOption);
                              formikForEditExpiry.setFieldValue(
                                "noOfDays",
                                selectedOption
                              );
                            }}
                          />
                          {formikForEditExpiry.errors.noOfDays && (
                            <TextError
                              msg={formikForEditExpiry.errors.noOfDays}
                            />
                          )}
                        </MDBox> */}

                        <MDBox mb={2}>
                          <MDTypography variant="h6">No of Days</MDTypography>
                          <MDInput
                            type="number"
                            name="noOfDays"
                            fullWidth
                            autoComplete="off"
                            onChange={formikForEditExpiry.handleChange}
                            onBlur={formikForEditExpiry.handleBlur}
                            value={formikForEditExpiry.values.noOfDays}
                            error={
                              formikForEditExpiry.touched.noOfDays &&
                              formikForEditExpiry.errors.noOfDays &&
                              true
                            }
                          />
                          {formikForEditExpiry.touched.noOfDays &&
                            formikForEditExpiry.errors.noOfDays && (
                              <TextError
                                msg={formikForEditExpiry.errors.noOfDays}
                              />
                            )}
                        </MDBox>

                        <MDBox mb={2}>
                          <MDTypography variant="h6">
                            Document (Jpeg/Png Only)
                          </MDTypography>
                          <input
                            name="imageFile"
                            style={{
                              borders: "none",
                              marginTop: "0px",
                              width: "100%",
                            }}
                            className="choose_file"
                            type="file"
                            autoComplete="off"
                            // accept=".jpg"
                            id="chooseFile"
                            onChange={(event) => handleChangeForDocument(event)}
                          />
                        </MDBox>

                        <MDBox mt={2}>
                          <Collapse in={isSuccessEditExpiry}>
                            <Alert
                              severity="success"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setIsSuccessEditExpiry(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            >
                              Expiry date edited successfully!
                            </Alert>
                          </Collapse>
                          <Collapse in={isErrorEditExpiry}>
                            <Alert
                              severity="error"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setIsErrorEditExpiry(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            >
                              {/* Could not Add the User and release! */}
                              {editExpiryErrorMsg}
                            </Alert>
                          </Collapse>
                        </MDBox>

                        <div className="mt-3">
                          <MDButton
                            type="submit"
                            variant="gradient"
                            color="success"
                            fullWidth
                            disabled={
                              !formikForEditExpiry.isValid ||
                              document == ("" || null) ||
                              flag == false
                            }
                          >
                            Confirm
                          </MDButton>
                        </div>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDCard>
              </Modal>
              <Modal
                open={openViewDocumentModal}
                onClose={handleCloseViewDocumentModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={styleForDocument}>
                  <MDBox ml={1} mt={-1} display="flex" alignItems="center">
                    <MDTypography variant="h5" fontWeight="medium" flexGrow={1}>
                      View Document
                    </MDTypography>
                    <MDBox>
                      <Tooltip title="Close">
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          onClick={handleCloseViewDocumentModal}
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </MDBox>
                  </MDBox>

                  <hr />

                  <MDBox pt={1} pb={3} px={1} height="100%">
                    <img
                      style={{
                        height: "100%",
                        width: "100%",
                        overflow: "auto",
                      }}
                      src={documentForView}
                    />
                  </MDBox>
                </MDCard>
              </Modal>
              <Modal
                open={openUpdateQIStatusModal}
                onClose={handleCloseUpdateQIStatusModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={deleteStyle}>
                  <MDTypography>
                    Do you want to update the status to Unrestricted-Use ?
                  </MDTypography>
                  <MDBox mt={3} display="flex">
                    <div>
                      <MDButton
                        style={{ width: "100%", marginRight: "0.5rem" }}
                        color="info"
                        variant="outlined"
                        onClick={handleCloseUpdateQIStatusModal}
                      >
                        Cancel
                      </MDButton>
                    </div>
                    <div style={{ float: "right" }}>
                      <MDButton
                        style={{ width: "100%", marginLeft: "0.5rem" }}
                        color="success"
                        onClick={updateQIStatusHandler}
                      >
                        Yes
                      </MDButton>
                    </div>
                  </MDBox>
                  <MDBox mt={2}>
                    <Collapse in={isSuccessUpdateQIStatus}>
                      <Alert
                        severity="success"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setIsSuccessUpdateQIStatus(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        Updated the status to Unrestricted-Use successfully
                      </Alert>
                    </Collapse>
                    <Collapse in={isErrorUpdateQIStatus}>
                      <Alert
                        severity="error"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setIsErrorUpdateQIStatus(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        {/* Could not Release For Issuance! */}
                        {updateQIStatusErrorMsg}
                      </Alert>
                    </Collapse>
                  </MDBox>
                </MDCard>
              </Modal>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      {loading && (
        <Backdrop
          sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {loadingData && (
        <Backdrop
          sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <MDTypography color="warning" variant="h2">
            Preparing Data. Please wait...
          </MDTypography>
        </Backdrop>
      )}
      <Footer />
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    getWarehouseOccupancyState: state.getWarehouseOccupancy,
    downloadWarehouseOccupancyState: state.downloadWarehouseOccupancyReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getWarehouseOccupancy: (pageNo, rowsPerPage, search, jwtToken) =>
      dispatch(getWarehouseOccupancy(pageNo, rowsPerPage, search, jwtToken)),
    downloadWarehouseOccupancy: (pageNo, rowsPerPage, search, jwtToken) =>
      dispatch(
        downloadWarehouseOccupancy(pageNo, rowsPerPage, search, jwtToken)
      ),
    editExpiry: (payload, jwtToken) => dispatch(editExpiry(payload, jwtToken)),
    documentUpload: (payload, jwtToken) =>
      dispatch(documentUpload(payload, jwtToken)),
    updateQIStatus: (payload, jwtToken) =>
      dispatch(updateQIStatus(payload, jwtToken)),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(WarehouseOccupancy);
